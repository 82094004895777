import React from "react";
import PropTypes from "prop-types";
import Image from "../../ui/Image/Image";
import Utils from "../../utils/Utils";
import "./School.scss";

const School = ({ lang, media }) => {
  if (lang !== "swedish") {
    return null;
  }

  const isSmall =
    media === Utils.media.MOBILE || media === Utils.media.LANDSCAPE;

  return (
    <div className="school">
      <div id="school-anchor" />
      <div className="date-tag">20 och 27 juli 2024</div>
      <div className="flex">
        <div className="school-image">
          {isSmall ? (
            <Image
              alt="Fossilskola"
              src="/images/school_m_800x494.jpg"
              ratio={800 / 494}
            />
          ) : null}
        </div>
        <div className="school-content">
          <div>
            <div className="school-top">
              <i>Fossilskola för familjen</i> <span>20 och 27 juli 2024</span>
            </div>
            <p>
              Fossil är rester av gammalt liv. I kalkstenen kan vi finna spår av
              tidiga djur som levde i haven när kalkstenen bildades för många
              miljoner år sedan.
            </p>
            <p>
              Under vår stund i Fossilskolan berättar geologen vad ett fossil
              är, hur de ser ut och hur djuren levde och såg ut en gång i tiden.
              Spännande!
            </p>
            <p>
              Efter det korta teorimomentet ger vi oss på de fossilrika
              stenarna. Med hammare och iförda hjälm och skyddsglasögon knackar
              vi fram vårt spännande minne från Öland.
            </p>
            <div className="school-info">
              Obs! Föräldrar ansvarar för de yngre fossiljägarna!
              <dl>
                <dt>Tider:</dt>
                <dd>kl 10, 13 och 15.</dd>
                {/* <dt>Pris:</dt>
                <dd>150 kr per &quot;fossiljägare&quot;</dd> */}
                <dt>Anmälan:</dt>
                <dd>070 -753 7890 (Ann-Charlotte)</dd>
                <dt>Plats:</dt>
                <dd>
                  Skyltar visar vägen till platsen (Norra täkten i Gillberga
                  bergbrott){" "}
                  <a
                    href="http://www.google.com/maps/place/57.1094650601936,16.895438501050776"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Gillberga Natur- och Kulturbrott
                  </a>
                </dd>
              </dl>
              <p>Hjälm, hammare och skyddsglasögon finns på plats. </p>
              👋 Ann-Charlotte Garhammar och geolog Jan Mikaelsson 🪨
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

School.propTypes = {
  lang: PropTypes.string.isRequired,
  media: PropTypes.string.isRequired,
};

export default School;
