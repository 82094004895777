import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Page from "../Page";
import Dates from "../../components/Calendar/Dates";
import Utils from "../../utils/Utils";
import AppLink from "../../ui/links/AppLink";
import Image from "../../ui/Image/Image";

import "./Stories.scss";

const Stories = (props) => {
  const { lang, media } = props;
  const useFullWidth = !(
    media === Utils.media.MOBILE ||
    media === Utils.media.LANDSCAPE ||
    media === Utils.media.TABLET ||
    media === Utils.media.DESKTOP
  );

  const isSwedish = lang === "swedish";

  let title = "Källa gamla kyrka";
  if (lang === "english") {
    title = "The Church of Källa...";
  } else if (lang === "german") {
    title = "Die Kirche von Källa...";
  }

  return (
    <Page title="Källa gamla kyrka | Mya idé">
      <section>
        <div className="stories-page">
          <div className="image-wrapper">
            <Image
              alt="Källa gamla kyrka"
              src="/images/church_1920x742.webp"
              ratio={1920 / 742}
            />
          </div>

          <div
            className={cx("calendar", {
              full: useFullWidth,
            })}
          >
            <Dates useFullWidth={useFullWidth} lang={lang} />

            <div className="calendar-info fossil">
              <div className="calendar-type">
                <div className="calendar-type-header">
                  <div className="bg" />
                  <h3>{title}</h3>
                </div>

                <div className="small mt-20">
                  Under knappa timmen berättas om sten och kyrka och vi lyssnar
                  till vad stenväggarna viskar. Viskar gör även källorna, som
                  får sin magiska kraft från underjorden. Det handlar om att
                  hela och att vörda. Ölands historia är inbyggd i Borgholms
                  slottsmurar, men också i mursträngar och fattiga boningar – på
                  alvar, i byar och på utmarker. Med hjälp av bilderna tar vår
                  fantasi fart och ger oss en insikt i öns svunna tider. Det är
                  en berättelse som lever och påverkar ännu idag.
                </div>
                {isSwedish ? (
                  <ul>
                    <li>
                      <b className="pictures-time">10 / 7</b>kl 20 - 21
                      <br />
                      <b className="pictures-time">17 / 7</b>kl 20 - 21
                      <br />
                      <b className="pictures-time">24 / 7</b>kl 20 - 21
                    </li>
                    <li>
                      <strong>200 kr</strong> per person
                    </li>
                    <li>
                      <u>Anmälan till 0707 - 53 78 90</u>, gärna SMS
                    </li>
                    <li>
                      <span>Mötesplats:</span> Källa gamla kyrka
                    </li>
                    <li>
                      <span>Källa:</span>{" "}
                      <AppLink
                        external
                        to="https://maps.app.goo.gl/iaByMVD6mn9Ebm6S8"
                      >
                        Latitud 57,1114, longitud 16,9863
                      </AppLink>
                    </li>
                    <li>
                      <span>Kontakt:</span> Ann-Charlotte Garhammar
                    </li>
                    <li>
                      <span>Telefon:</span> 0707 537890
                    </li>
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

Stories.propTypes = {
  media: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Stories;
