import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import Page from "../Page";
import Image from "../../ui/Image/Image";
import Utils from "../../utils/Utils";
import AppLink from "../../ui/links/AppLink";
import Dates from "../../components/Calendar/Dates";

import "./Shines.scss";

const Shines = ({ lang, media }) => {
  const small = media === Utils.media.MOBILE || media === Utils.media.LANDSCAPE;
  const useFullWidth = !(
    media === Utils.media.MOBILE ||
    media === Utils.media.LANDSCAPE ||
    media === Utils.media.TABLET ||
    media === Utils.media.DESKTOP
  );

  const image = small ? (
    <Image
      alt="Sandvik öland"
      src="/images/sandvik_landscape.jpg"
      ratio={2667 / 1450}
    />
  ) : (
    <Image
      alt="Sandvik öland"
      src="/images/sandvik_landscape_blue_filter_small.jpg"
      ratio={2667 / 664}
    />
  );

  return (
    <Page title="Öland spirar | Mya idé">
      <section>
        <div className="shines-page">
          <div className="image-wrapper">{image}</div>
          <div className="text-right">
            Foto:{" "}
            <AppLink external to="https://unsplash.com/@jonflobrant">
              Jon Flobrant
            </AppLink>
          </div>

          <div
            className={cx("calendar", {
              full: useFullWidth,
            })}
          >
            <Dates useFullWidth={useFullWidth} lang={lang} />

            <div className="calendar-info shines">
              <div className="calendar-type">
                <div className="calendar-type-header">
                  <div className="bg" />
                  <h3>Stenen som får Öland att spira!</h3>
                </div>
                <div className="mt-20">
                  Kom till en lättsam guidning bland flisor och raukar - om
                  sten, mager jord och lila blommor.
                </div>
                <ul>
                  <li>
                    <span className="day-label">Fredag</span> &nbsp;
                    <b className="pictures-time">10 / 5</b> kl 11 - 12
                  </li>
                  <li>
                    <strong>150 kr</strong> per person
                  </li>
                  <li>
                    <span>Mötesplats:</span> Byrums raukar, södra p-platsen
                  </li>
                  <li>
                    <span>Koordinater:</span>{" "}
                    <AppLink
                      external
                      to="https://goo.gl/maps/aBdea5WPd6q4gY3p8"
                    >
                      Latitud 57,222592, longitud 16,951993
                    </AppLink>
                  </li>
                  <li>
                    <u>Anmälan via SMS 0707-537890 (Ann-Charlotte)</u>
                  </li>
                </ul>
                /Ölandsguide Ann-Charlotte Garhammar
              </div>
            </div>
          </div>

          {/* <div className="shines-inner">
            <h2>Öland spirar, 11 maj 2019</h2>

            <div className="morning">
              <p>
                Byrums raukar
                <br />
                Kl. 10.30-11.30
              </p>

              <p className="ingress">
                <b>
                  Kom till en timmes lättsam guidning bland flisor och raukar​ -
                  om sten, mager jord och lila blommor!
                </b>
              </p>

              <p className="body">
                Vi möts vid P-platsen söder om Byrums raukar för att promenera
                ett hundratal meter längs kusten. Här talar vi lättsamt om
                geologi och beundrar olika stenars färg och form. Om vi letar
                hittar vi urtida bläckfiskar som simmar i stenen.
              </p>

              <dl>
                <dt>Plats: </dt>
                <dd>
                  <b>Byrums raukar, södra p-platsen</b>
                </dd>
                <dt>Pris:</dt>
                <dd>
                  <b>100 kr/p</b>
                </dd>
                <dt>Kontakt:</dt>
                <dd>
                  <b>Ann-Charlotte Garhammar</b>
                </dd>
                <dt>Mobil:</dt>
                <dd>
                  <b>070-753 7890</b>
                </dd>
                <dt />
                <dd>
                  <b>Ingen föranmälan</b>
                </dd>
              </dl>
            </div>

            <div className="afternoon">
              <p>
                Gillberga natur- och kulturbrott
                <br />
                Kl. 13.00-14.00
              </p>

              <p className="ingress">
                <b>60 minuter med kalksten, kristalläpplen &amp; ölandstokar</b>
              </p>

              <p className="body">
                Möt den höga väggen i Gillberga bergbrott och förundras över
                dess ålder och mäktighet. I jämförelse är människan liten och
                dess tid på jorden kort. Vår planets historia sägs vara 4,6
                miljarder år gammal och själva lagret av kalksten är nästan 500
                miljoner år. Under en timme fördjupar vi oss på lättsamt sätt i
                den spännande geologin.
              </p>

              <dl>
                <dt>Plats: </dt>
                <dd>
                  <b>Gillberga Natur- och Kulturbrott</b>
                </dd>
                <dt>Pris:</dt>
                <dd>
                  <b>100 kr/p</b>
                </dd>
                <dt>Kontakt:</dt>
                <dd>
                  <b>Ann-Charlotte Garhammar</b>
                </dd>
                <dt>Mobil:</dt>
                <dd>
                  <b>070-753 7890</b>
                </dd>
                <dt />
                <dd>
                  <b>Ingen föranmälan</b>
                </dd>
              </dl>
            </div>
          </div> */}
        </div>
      </section>
    </Page>
  );
};

Shines.propTypes = {
  lang: PropTypes.string.isRequired,
  media: PropTypes.string.isRequired,
};

export default Shines;
